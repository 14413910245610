import { Container } from "@mui/material";
import * as React from "react";
import ImageGallery from "react-image-gallery";

const publicUrl =
  process.env.NODE_ENV === "development"
    ? ""
    : "https://dbm4qye01gc25.cloudfront.net/main/latest";

const images = [
  {
    thumbnail: `${publicUrl}/images/1.jpg`,
    original: `${publicUrl}/images/1-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/2.jpg`,
    original: `${publicUrl}/images/2-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/3.jpg`,
    original: `${publicUrl}/images/3-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/4.jpg`,
    original: `${publicUrl}/images/4-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/5.jpg`,
    original: `${publicUrl}/images/5-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/6.jpg`,
    original: `${publicUrl}/images/6-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/7.jpg`,
    original: `${publicUrl}/images/7-orig.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Anubis.JPG`,
    original: `${publicUrl}/images/Anubis.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Apollo and Daphne.JPG`,
    original: `${publicUrl}/images/Apollo and Daphne.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Atlas.JPG`,
    original: `${publicUrl}/images/Atlas.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Augustus Prima Porta.JPG`,
    original: `${publicUrl}/images/Augustus Prima Porta.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Bull Skull.JPG`,
    original: `${publicUrl}/images/Bull Skull.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Cathedral in Black.JPG`,
    original: `${publicUrl}/images/Cathedral in Black.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Cathedral in White.JPG`,
    original: `${publicUrl}/images/Cathedral in White.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Child.JPG`,
    original: `${publicUrl}/images/Child.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Crow1.JPG`,
    original: `${publicUrl}/images/Crow1.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Crow.JPG`,
    original: `${publicUrl}/images/Crow.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Crow Skull.JPG`,
    original: `${publicUrl}/images/Crow Skull.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Deer Skull Gold Antlers.JPG`,
    original: `${publicUrl}/images/Deer Skull Gold Antlers.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Deer Skull.JPG`,
    original: `${publicUrl}/images/Deer Skull.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Fragmentary Colossal Head.JPG`,
    original: `${publicUrl}/images/Fragmentary Colossal Head.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Gayer Anderson Cat.JPG`,
    original: `${publicUrl}/images/Gayer Anderson Cat.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Hand of Adam.JPG`,
    original: `${publicUrl}/images/Hand of Adam.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Head of Dionysus.JPG`,
    original: `${publicUrl}/images/Head of Dionysus.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Horse Skull.JPG`,
    original: `${publicUrl}/images/Horse Skull.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/jellyfish1.JPG`,
    original: `${publicUrl}/images/jellyfish1.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/jellyfish2.JPG`,
    original: `${publicUrl}/images/jellyfish2.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/jellyfish3.JPG`,
    original: `${publicUrl}/images/jellyfish3.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/jellyfish.JPG`,
    original: `${publicUrl}/images/jellyfish.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Large David Head Planter.JPG`,
    original: `${publicUrl}/images/Large David Head Planter.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Large Venus.JPG`,
    original: `${publicUrl}/images/Large Venus.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Majka i dijete Mother and child.JPG`,
    original: `${publicUrl}/images/Majka i dijete Mother and child.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Maya Plisetskaya.JPG`,
    original: `${publicUrl}/images/Maya Plisetskaya.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Medusa.JPG`,
    original: `${publicUrl}/images/Medusa.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Moai.JPG`,
    original: `${publicUrl}/images/Moai.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Monkey with Skull.JPG`,
    original: `${publicUrl}/images/Monkey with Skull.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/mushroom.jpg`,
    original: `${publicUrl}/images/mushroom.jpg`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Panther and Cubs - Copy.JPG`,
    original: `${publicUrl}/images/Panther and Cubs - Copy.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Reclining Naid.JPG`,
    original: `${publicUrl}/images/Reclining Naid.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Sappho.JPG`,
    original: `${publicUrl}/images/Sappho.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Still Water - Copy.JPG`,
    original: `${publicUrl}/images/Still Water - Copy.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Still Water.JPG`,
    original: `${publicUrl}/images/Still Water.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/The Bather.JPG`,
    original: `${publicUrl}/images/The Bather.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Thinker.JPG`,
    original: `${publicUrl}/images/Thinker.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Two Greyhounds.JPG`,
    original: `${publicUrl}/images/Two Greyhounds.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Venus de Milo.JPG`,
    original: `${publicUrl}/images/Venus de Milo.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Venus Italica.JPG`,
    original: `${publicUrl}/images/Venus Italica.JPG`,
  },
  {
    thumbnail: `${publicUrl}/images/small/Virgin and Child.JPG`,
    original: `${publicUrl}/images/Virgin and Child.JPG`,
  },

];

export default function ArtImageGallery() {
  return (
    <Container component="section" sx={{ mt: 2 }}>
      <ImageGallery lazyLoad={true} items={images}></ImageGallery>
    </Container>
  );
}
