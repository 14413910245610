import * as React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import "./ProductValues.css";
const artImage = require("../../images/theart.jpg");
const processImage = require("../../images/theprocess.jpg");
const plantsImage = require("../../images/plants.jpg");

const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={artImage}
                alt="suitcase"
                sx={{ height: 75 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                The Art
              </Typography>
              <Typography variant="h5">
                Scan the World is a worldwide initiave deigned to preserve and
                share cultural artifacts through 3D scanning. The scans are then
                uploaded and shared in the public domain.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={plantsImage}
                alt="graph"
                sx={{ height: 75 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                The Plants
              </Typography>
              <Typography variant="h5">
                Our Tillandsias (air plants) are sustainably sourced and
                nurtured for serveral months in our tiny dining room greenhouse
                before being selected to adorn the statuettes. Current species
                include <span className="species">abdita</span>,{" "}
                <span className="species">red abdita</span>,{" "}
                <span className="species">harrisii</span>, and
                <span className="species">tenuifolia</span>. Bulbous varieties
                include <span className="species">ionantha guatemala</span> and
                <span className="species">ionantha scaposa</span>, along with
                the "slenders" of <span className="species">juncea</span> and{" "}
                <span className="species">argentea</span>.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={processImage}
                alt="clock"
                sx={{ height: 75 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                The Process
              </Typography>
              <Typography variant="h5">
                The 3D scans are printed using traditional PLA filament or
                resin, then cured and painted to create miniature replicas of
                the cultural artifacts. Plants specifically chosen to complement
                the statues are affixed using a plant safe waterproof glue to
                produce mini works of <span className="species">LivingArt</span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
