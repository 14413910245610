import * as React from "react";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import ProductValues from "./modules/views/ProductValues";
import ProductCTA from "./modules/views/ProductCTA";
import AppAppBar from "./modules/views/AppAppBar";
import withRoot from "./modules/withRoot";
import Helmet from "react-helmet";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Terms from "./Terms";
import Privacy from "./Privacy";
import ArtImageGallery from "./modules/components/ArtImageGallery";
import FourOFourPage from "./404";

function Index() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Living Art Statuettes</title>
      </Helmet>
      <AppAppBar />
      <BrowserRouter>
        <Routes>
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route
            path="/"
            element={
              <>
                <ProductHero />
                <ProductValues />
                <ArtImageGallery />
                <ProductCTA />
                <ProductSmokingHero />
              </>
            }
          />
          <Route path="/404" element={<FourOFourPage />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </BrowserRouter>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
